import { FC, useEffect, useState } from 'react';
import { useSnackbar } from '../../hooks/snackbar';
import { useUserContext } from '../../providers/UserProvider';
import { useMessagesGetByProfileLazyQuery } from '../../libs/graphql/betzoneDirectusAPI/queries/__generated__/messages-get-by-profile.query.generated';
import { useMessageDeleteMutation } from '../../libs/graphql/betzoneDirectusAPI/mutations/__generated__/message-delete.mutation.generated';
import { Endpoint } from '../../models/api.model';
import { Button } from '../shared/Button';
import { useTranslations } from '../../hooks/useTranslationsHelper';
import DOMPurify from 'dompurify';
import { filterSpecialHTMLCharacters } from '../../utils/string';
import { Heading } from '../shared/Heading';
import { Paragraph } from '../shared/Paragraph';
import { storageAfterLogin } from '../../utils/storage';
import './LoggedInMessages.scss';

export const LoggedInMessages: FC = () => {
  const { t } = useTranslations();
  const { addSnack } = useSnackbar();
  const { userProfileId } = useUserContext();

  const sanitizer = DOMPurify.sanitize;

  const afterLogin = storageAfterLogin.get();

  const [messageId, setMessageId] = useState<string>('');

  const [fetchMessages, { data: userMessages, error: messageError }] = useMessagesGetByProfileLazyQuery({
    variables: {
      userProfileId,
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  const [messageDeleteMutation, { error: deleteMessageError }] = useMessageDeleteMutation({
    variables: {
      id: messageId,
    },
    context: { endpoint: Endpoint.betzoneDirectusAPI },
  });

  useEffect(() => {
    messageId && messageDeleteMutation();
  }, [messageId]);

  useEffect(() => {
    userMessages?.messages?.map((message, index) => {
      const { id, message_header, message_content } = message;

      return addSnack({
        theme: 'dark',
        type: 'info',
        message: (
          <div className="message-snack">
            <Heading level={5}>{message_header}</Heading>
            <Paragraph
              noMargin
              dangerouslySetInnerHTML={{
                __html: filterSpecialHTMLCharacters(sanitizer(String(message_content))),
              }}
              className="aem-text"
            />
          </div>
        ),
        // eslint-disable-next-line react/display-name
        action: () => (
          <Button
            type="button"
            variant="text"
            size="medium"
            className="reality-checks__undo-btn"
            onClick={(): void => setMessageId(String(id))}
          >
            {t('buttons.delete')}
          </Button>
        ),
        isDismissible: true,
        displayTime: 0,
      });
    });
  }, [userMessages]);

  useEffect(() => {
    const resMessage = messageError || deleteMessageError;

    resMessage &&
      addSnack({
        theme: 'dark',
        type: 'error',
        message: String(resMessage),
        isDismissible: true,
      });
  }, [messageError, deleteMessageError]);

  useEffect(() => {
    if (afterLogin) {
      fetchMessages();
      storageAfterLogin.set({ value: false });
    }
  }, [afterLogin]);

  return null;
};
