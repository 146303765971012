import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessagesGetByProfileQueryVariables = Types.Exact<{
  userProfileId: Types.Scalars['String']['input'];
}>;


export type MessagesGetByProfileQuery = { __typename?: 'Query', messages: Array<{ __typename?: 'messages', id: string, message_id?: string | null, message_header?: string | null, message_content?: string | null }> };


export const MessagesGetByProfileDocument = gql`
    query MessagesGetByProfile($userProfileId: String!) {
  messages(
    filter: {status: {_eq: "published"}, user_profile_id: {_eq: $userProfileId}}
  ) {
    id
    message_id
    message_header
    message_content
  }
}
    `;

/**
 * __useMessagesGetByProfileQuery__
 *
 * To run a query within a React component, call `useMessagesGetByProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesGetByProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesGetByProfileQuery({
 *   variables: {
 *      userProfileId: // value for 'userProfileId'
 *   },
 * });
 */
export function useMessagesGetByProfileQuery(baseOptions: Apollo.QueryHookOptions<MessagesGetByProfileQuery, MessagesGetByProfileQueryVariables> & ({ variables: MessagesGetByProfileQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesGetByProfileQuery, MessagesGetByProfileQueryVariables>(MessagesGetByProfileDocument, options);
      }
export function useMessagesGetByProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesGetByProfileQuery, MessagesGetByProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesGetByProfileQuery, MessagesGetByProfileQueryVariables>(MessagesGetByProfileDocument, options);
        }
export function useMessagesGetByProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessagesGetByProfileQuery, MessagesGetByProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessagesGetByProfileQuery, MessagesGetByProfileQueryVariables>(MessagesGetByProfileDocument, options);
        }
export type MessagesGetByProfileQueryHookResult = ReturnType<typeof useMessagesGetByProfileQuery>;
export type MessagesGetByProfileLazyQueryHookResult = ReturnType<typeof useMessagesGetByProfileLazyQuery>;
export type MessagesGetByProfileSuspenseQueryHookResult = ReturnType<typeof useMessagesGetByProfileSuspenseQuery>;
export type MessagesGetByProfileQueryResult = Apollo.QueryResult<MessagesGetByProfileQuery, MessagesGetByProfileQueryVariables>;